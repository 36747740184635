import * as React from 'react';
import styles from '../agtech.module.css';
import loadable from '@loadable/component';

import Layout from '../components/layout';
import Bullets from 'components/bullets';
import Tile from 'components/tile';
import GetConsultation from 'components/free-consultation';
import Blog from 'components/blog/widget';
import Booking from 'components/feedback';
import { Dokka, TradersAlloy, Wagepoint, Moolax } from 'components/carousel';
import identificators from 'components/googleAnalyticsIdentificators';

import { meta } from '../../../metaData';
import FAQ from 'components/faq';
import { useFaqData } from 'hooks/useFaqData';

const Routes = require('../../routes').Routes;

const SwiperSlider = loadable(() => import('components/swiper-slider'));

const LIST = [
  {
    title: 'AI-enabled Solutions',
    text: 'Stay ahead of the times with AI-powered back-office operations, predictive analytics and invaluable Big Data-driven insights',
  },
  {
    title: 'Finance Management Solutions',
    text: 'We create products that let end-users manage and control their personal finances, monitor and categorize expenses and make data-driven forecasts.',
  },
  {
    title: 'Blockchain-Based Solutions',
    text: 'Leverage our Blockchain-based expertise to protect your end-users’ sensitive data and ensure at-a-glance transactions with no third parties involved.',
  },
  {
    title: 'Digital Banking Solutions',
    text: 'Provide a decent self-service experience across any device with a fraud-proof banking app to win your customers’ loyalty',
  },
  {
    title: 'Payments and Billing Solutions',
    text: 'Keep your clients satisfied with a full-cycle billing and payment statistics, smart real-time reporting and finance automation.',
  },
  {
    title: 'Fintech App Development and Upgrade',
    text: 'We design a new project or enhance your solution with leading-edge UI/UX practices to let you deliver an outstanding user experience.',
  },
];

const SERVICES = [
  {
    name: 'agritech-startup',
    title: 'FinTech Startup',
    text: 'Have a revolutionary idea? We are eager to create a project from scratch or perfect your existing MVP.',
    to: Routes.SERVICES_MVP_DEVELOPMENT,
  },
  {
    name: 'agritech-b2b-company',
    title: 'FinTech B2B Company',
    text: 'We gather a dedicated team to take care of every development step while saving your budget.',
    to: Routes.SERVICES_DEDICATED_TEAM,
  },
  {
    name: 'farm-owner',
    title: 'Bank Owners',
    text: 'We embrace the latest tech trends to reshape your legacy infrastructure with digitally-enabled solutions.',
    to: Routes.SERVICES_DIGITAL_TRANSFORMATION,
  },
];

interface Props {
  pathContext: {
    prePost: ReadonlyArray<any>;
  };
  location: Location;
}

function Fintech({ pathContext: { prePost }, location }: Props) {
  const faqData = useFaqData('allStrapiIndustry', 'Fintech');

  return (
    <Layout
      title="FinTech Software Development"
      subtitle="We help FinTech-related startups, and financial institutions create smoothly customized, highly secured and AI-fueled solutions to increase their revenue and improve the entire workflow."
      linkText="Contact us"
      metaData={{ main: meta.fintech, faq: faqData?.list }}
      pathname={location.pathname}
      linkId={identificators.CONTACT_US_HEADER}
    >
      <section className="section">
        <div className="inner">
          <h2 className="title">FinTech products we can build</h2>
          <p className="subtitle">
            Convert your day-to-day practice into a delightful customer experience
          </p>
          <Bullets list={LIST} className={styles.bullets} />
        </div>
      </section>
      <SwiperSlider>
        <Dokka />
        <Moolax />
        <TradersAlloy />
        <Wagepoint />
      </SwiperSlider>

      <section className="section">
        <div className="inner">
          <h2 className="title">Services we provide</h2>
          <p className="subtitle">Leave the development process to us</p>
          <Tile list={SERVICES} className={styles.imageBullets} />
        </div>
      </section>
      <GetConsultation
        title="Let’s start FinTech project together"
        text="Empower your Fintech business with intuitive and multi-functioning solutions"
        linkText="schedule a call"
        linkId={identificators.SCHEDULE_A_CALL_BANNER}
      />
      <section className="section">
        <div className="inner">
          <FAQ data={faqData} />
          <Blog posts={prePost} />
        </div>
      </section>
      <Booking />
    </Layout>
  );
}

export default Fintech;
